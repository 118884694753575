<template>
    <div class="earn-page">
        <div class="container">
            <div class="earn-offer-block">
                <div class="container">
                    <h1 class="title">
                        {{ $i18n.get(`TRUE Earn`) }}
                    </h1>

                    <div class="sub-title">
                        {{ $i18n.get(`The place where your crypto assets work for you`) }}
                    </div>
                </div>
            </div>

            <div class="earn-links">
                <template v-if="isLoading">
                    <earn-item-preloader v-for="n in 4" :key="n"></earn-item-preloader>
                </template>

                <template v-else>
<!--                    <router-link :to="{name: 'swap'}" class="item-link -swap" v-if="view.swap.active">-->
<!--                        <div class="image"></div>-->

<!--&lt;!&ndash;                        <div class="info">APR: {{ Math.floor(view.swap.apr) }}%</div>&ndash;&gt;-->

<!--                        <div class="content">-->
<!--                            <div class="left">-->
<!--                                <div class="title">-->
<!--                                    {{ $i18n.get(`Swap TFL-TFT`) }}-->

<!--                                    <div class="soon">{{ $i18n.get(`soon`) }}</div>-->
<!--                                </div>-->

<!--                                <div class="description">-->
<!--                                    {{ $i18n.get(`Swap your TFL token and get x5 TFT, also receive regular rewards`) }}-->
<!--                                </div>-->
<!--                            </div>-->

<!--                            <w-button v-if="view.swap.exist" type="white-border" :to="{name: 'swap'}">-->
<!--                                {{ $i18n.get(`Details`) }}-->
<!--                            </w-button>-->

<!--                            <w-button v-else type="orange" :to="{name: 'swap'}">-->
<!--                                {{ $i18n.get(`Join`) }}-->
<!--                            </w-button>-->
<!--                        </div>-->
<!--                    </router-link>-->

                    <template v-if="view.stacking.active">
                        <router-link :to="{name: 'staking'}"  class="item-link -stacking" :class="{'-disabled': !view.stacking.active}">
                            <div class="image"></div>

<!--                            <div class="info">APR: {{ Math.floor(view.stacking.apr) }}%</div>-->

                            <div class="content">
                                <div class="left">
                                    <div class="title">
                                        {{ $i18n.get(`Staking`) }}

                                        <div class="soon">{{ $i18n.get(`soon`) }}</div>
                                    </div>

                                    <div class="description">
                                        {{ $i18n.get(`Stake your tokens and receive regular rewards`) }}
                                    </div>
                                </div>

                                <w-button v-if="view.stacking.active" type="white-border" :to="{name: 'staking'}">
                                    {{ $i18n.get(`Details`) }}
                                </w-button>
                            </div>
                        </router-link>
                    </template>

                    <template v-else>
                        <div class="item-link -stacking" :class="{'-disabled': !view.stacking.active}">
                            <div class="image"></div>

<!--                            <div class="info">APR: {{ Math.floor(view.stacking.apr) }}%</div>-->

                            <div class="content">
                                <div class="left">
                                    <div class="title">
                                        {{ $i18n.get(`Staking`) }}

                                        <div class="soon">{{ $i18n.get(`soon`) }}</div>
                                    </div>

                                    <div class="description">
                                        {{ $i18n.get(`Stake your tokens and receive regular rewards`) }}
                                    </div>
                                </div>

                                <w-button v-if="view.stacking.active" type="white-border" :to="{name: 'staking'}">
                                    {{ $i18n.get(`Details`) }}
                                </w-button>
                            </div>
                        </div>
                    </template>

                    <div class="item-link -farming" :class="{'-disabled': !view.farming.active}">
                        <div class="image"></div>

<!--                         <div class="info">APR: 1000%</div>-->

                        <div class="content">
                            <div class="left">
                                <div class="title">
                                    {{ $i18n.get(`Farming`) }}

                                    <div class="soon">{{ $i18n.get(`soon`) }}</div>
                                </div>

                                <div class="description">
                                    {{ $i18n.get(`Stake your liquidity tokens and receive regular rewards`) }}
                                </div>
                            </div>
                        </div>
                    </div>

<!--                    <div class="item-link -flips -secondary" :class="{ '-disabled': !view.flips }">-->
<!--                        <div class="image"></div>-->

<!--                        <div class="info">{{ $i18n.get(`Game`) }}</div>-->

<!--                        <div class="content">-->
<!--                            <div class="left">-->
<!--                                <div class="title">-->
<!--                                    {{ $i18n.get(`Flip's Star`) }}-->

<!--                                    <div class="soon">{{ $i18n.get(`soon`) }}</div>-->
<!--                                </div>-->

<!--                                <div class="description">-->
<!--                                    {{ $i18n.get(`Our iconic game`) }}-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="item-link -holders -secondary" :class="{ '-disabled': !view.holdersGames }">-->
<!--                        <div class="image"></div>-->

<!--                        <div class="info">{{ $i18n.get(`Game`) }}</div>-->

<!--                        <div class="content">-->
<!--                            <div class="left">-->
<!--                                <div class="title">-->
<!--                                    {{ $i18n.get(`Holder's Game`) }}-->

<!--                                    <div class="soon">{{ $i18n.get(`soon`) }}</div>-->
<!--                                </div>-->

<!--                                <div class="description">-->
<!--                                    {{ $i18n.get(`The win-win game for holders`) }}-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import EarnItemPreloader from './snippets/EarnItemPreloader'

export default {
    name: 'earn',
    components: {
        'earn-item-preloader': EarnItemPreloader
    },
    data() {
        return {
            isLoading: true,
            view: {
                swap: {
                    active: false,
                    apr: 0
                },
                stacking: {
                    active: true,
                    apr: 0
                },
                farming: {
                    active: false,
                    apr: 100
                },
                flips: false,
                holdersGames: false,
                flipmas: false
            }
        }
    },
    watch: {
        isGuest() {
            if (!this.isGuest) {
                this.load()
            }
        }
    },
    computed: {
        isGuest() {
            return this.$store.state.isGuest
        }
    },
    methods: {
        load() {
            this.$request.get('/earn', {}, response => {
                this.isLoading = false

                this.apr = response.earn.apr
                this.view = response.earn.view
            })
        }
    },
    created() {
        this.load()
    }
}
</script>

<style lang="less">
.earn-page {
    background: var(--main-grey);
    
    > .container {
        > .earn-offer-block {
            background-color: var(--dark-blue);
            background-image: url(./images/bg-earn.png);
            background-size: 2560px 100%;
            background-position: center;
            min-height: 140px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: var(--white);

            > .container {
                width: var(--main-width-full);
                margin: 0 auto;

                > .title {
                    font-weight: 700;
                    font-size: 46px;
                    line-height: 144%;
                }

                > .sub-title {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 180%;
                }
            }
        }

        > .earn-links {
            width: var(--main-width-full);
            margin: 80px auto;
            display: flex;
            flex-wrap: wrap;
            gap: 40px;

            > .item-link {
                background-color: var(--dark-blue);
                width: 570px;
                border-radius: 30px;
                height: 300px;
                position: relative;
                padding: 40px;
                display: flex;
                flex-direction: column;
                //justify-content: space-between;
                justify-content: flex-end;

                > .image {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-size: 101%;
                    background-position: center;
                    background-repeat: no-repeat;
                    border-radius: 30px;
                }

                > .info {
                    z-index: 2;
                    align-self: flex-start;
                    color: var(--white);
                    background: var(--white-20);
                    backdrop-filter: blur(10px);
                    padding: 10px 20px;
                    border-radius: 100px;
                }

                > .content {
                    z-index: 2;
                    color: var(--white);
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    > .left {
                        max-width: 285px;

                        > .title {
                            font-weight: 700;
                            font-size: 24px;
                            line-height: 144%;
                            display: flex;
                            align-items: center;

                            > .soon {
                                margin-left: 5px;
                                background: linear-gradient(85.73deg, #5F8CFF -25.39%, #CB34E3 114.43%);
                                border-radius: 15px 15px 15px 0;
                                padding: 0 6px;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 18px;
                                display: none;
                            }
                        }

                        > .description {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 144%;
                        }
                    }
                }

                &::before {
                    z-index: 1;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: linear-gradient(180deg, rgba(0, 26, 84, 0) 41.67%, #001A54 105.33%);
                    border-radius: 30px;
                }

                &.-disabled {
                    > .image {
                        filter: grayscale(100%);
                    }

                    > .content {
                        > .btn {
                            display: none;
                        }
                    }

                    &::before {
                        filter: grayscale(100%);
                    }

                    &::after {
                        z-index: 1;
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: #2a4d97;
                        opacity: 0.5;
                        border-radius: 30px;
                    }

                    > .content {
                        > .left {
                            > .title {
                                > .soon {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                &.-secondary {
                    > .info {
                        padding: 4px 12px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 144%;
                    }
                }

                &.-swap {
                    > .image {
                        background-image: url(./images/swap-bg.webp);
                    }
                }

                &.-stacking {
                    > .image {
                        background-image: url(./images/bg-stacking.png);
                    }
                }

                &.-farming {
                    
                    > .image {
                        background-image: url(./images/bg-farming.png);
                    }
                }

                &.-flips {
                    > .image {
                        background-image: url(./images/flips-bg.webp);
                    }
                }

                &.-holders {
                    > .image {
                        background-image: url(./images/holders-games-bg.webp);
                    }
                }

                &.-flipmas {
                    > .image {
                        background-image: url(./images/flipmas-bg.webp);
                    }
                }
            }
        }
    }
}

@media (max-width: 1279px) {
    .earn-page {
        > .container {
            > .earn-links {
                width: var(--main-width-full);
                margin: 50px auto;
                display: flex;
                flex-wrap: wrap;
                gap: 20px;

                > .item-link {
                    background-color: var(--dark-blue);
                    width: calc(100% / 2 - 10px);
                    border-radius: 30px;
                    height: 280px;
                    position: relative;
                    padding: 35px;

                    > .image {
                        background-size: cover;
                        background-position: center;
                    }

                    > .info {
                        z-index: 2;
                        align-self: flex-start;
                        color: var(--white);
                        background: var(--white-20);
                        backdrop-filter: blur(10px);
                        padding: 10px 20px;
                        border-radius: 100px;
                    }

                    &.-secondary {
                        > .info {
                            padding: 4px 12px;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 144%;
                        }
                    }

                    &.-farming {

                        > .image {
                            background-image: url(./images/farming-bg.webp);
                            background-position: right;
                        }
                    }

                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .earn-page {
        > .container {
            > .earn-offer-block {
                > .container {
                    > .title {
                        font-size: 36px;
                        line-height: 144%;
                    }

                    > .sub-title {
                        font-size: 14px;
                        line-height: 180%;
                    }
                }
            }

            > .earn-links {
                > .item-link {
                    padding: 25px;

                    > .info {
                        font-size: 12px;
                        line-height: 144%;
                    }

                    > .content {
                        flex-direction: column;
                        align-items: flex-start;
                        row-gap: 10px;

                        > .left {
                            max-width: 250px;

                            > .title {
                                font-size: 20px;
                                line-height: 144%;

                                > .soon {
                                    margin-left: 5px;
                                    background: linear-gradient(85.73deg, #5F8CFF -25.39%, #CB34E3 114.43%);
                                    border-radius: 15px 15px 15px 0;
                                    padding: 0 6px;
                                    font-weight: 500;
                                    font-size: 12px;
                                    line-height: 18px;
                                    display: none;
                                }
                            }

                            > .description {
                                font-size: 14px;
                                line-height: 21px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .earn-page {
        > .container {
            > .earn-offer-block {
                min-height: 100px;
                padding: 15px;

                > .container {

                    > .title {
                        font-size: 24px;
                        line-height: 32px;
                    }

                    > .sub-title {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }

            > .earn-links {
                flex-direction: column;
                padding: 20px 15px;
                margin: 0;
                gap: 15px;

                > .item-link {
                    width: 100%;
                    padding: 15px;

                    > .info {
                        font-size: 12px;
                        line-height: 144%;
                        padding: 5px 10px;
                    }

                    > .content {

                        > .left {

                            > .title {
                                font-size: 18px;
                                line-height: 144%;

                                > .soon {
                                    font-size: 11px;
                                    line-height: 16px;
                                }
                            }

                            > .description {
                                font-size: 12px;
                                line-height: 20px;
                            }
                        }

                        > .btn {
                            align-self: stretch;
                            height: 40px;
                        }
                    }

                    &.-disabled {
                        > .image {
                            filter: grayscale(100%);
                        }

                        > .content {
                            > .btn {
                                display: none;
                            }
                        }

                        &::before {
                            filter: grayscale(100%);
                        }

                        &::after {
                            z-index: 1;
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background: #2a4d97;
                            opacity: 0.5;
                            border-radius: 30px;
                        }

                        > .content {
                            > .left {
                                > .title {
                                    > .soon {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }

                    &.-secondary {
                        > .info {
                            padding: 4px 12px;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 144%;
                        }
                    }

                    &.-swap {
                        > .image {
                            background-image: url(./images/swap-bg.webp);
                        }
                    }

                    &.-stacking {
                        > .image {
                            background-position: right;
                        }
                    }
                }
            }
        }
    }
}
</style>
