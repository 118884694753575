<template>
    <div class="earn-item-preloader">
        <div class="head">
            <div class="element"></div>
        </div>
        <div class="bottom">
            <div class="-left">
                <div class="element -first"></div>
                <div class="element -second"></div>
                <div class="element -third"></div>
            </div>
            <div class="element -right"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'earn-item-preloader'
}
</script>

<style lang="less">
.earn-item-preloader {
    background-color: var(--preloader-background);
    /*width: 570px;*/
    width: calc((100% - 40px) / 2);
    border-radius: 30px;
    height: 300px;
    position: relative;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .element {
        background: var(--white);
        border-radius: 100px;
    }

    .head {
        display: flex;
        justify-content: flex-start;

        > .element {
            width: 120px;
            height: 43px;
        }
    }

    .bottom > {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        > .-left {
            flex: 1;

            > .-first {
                width: 60%;
                max-width: 215px;
                height: 20px;
                margin-bottom: 17px;
            }

            > .-second {
                width: 77%;
                max-width: 279px;
                height: 12px;
                margin-bottom: 11px;
            }

            > .-third {
                width: 63%;
                max-width: 230px;
                height: 12px;
            }
        }

        > .-right {
            width: 125px;
            height: 60px;
        }
    }
}

@media (max-width: 1279px) {
    .earn-item-preloader {
        .bottom {
            flex-direction: column;

            > .-left {
                width: 100%;
                margin-bottom: 20px;
            }

            > .-right {
                width: 100%;
            }
        }
    }
}

@media (max-width: 767px) {
    .earn-item-preloader {
        width: 100%;
        padding: 15px;

        > .head {
            > .element {
                width: 92px;
                height: 28px;
            }
        }

        > .bottom {
            > .-left {
                .-first {
                    height: 20px;
                }
            }

            > .-right {
                height: 40px;
            }
        }
    }
}
</style>
